<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog.dialog"
      eager
      fullscreen
      hide-overlay
      :persistent="persistent.persistent"
      transition="dialog-bottom-transition"
    >
      <v-card v-if="dialog.dialog">
        <v-toolbar dark color="blue-fmedical">
          <v-toolbar-title class="pt-4">
            <p class="text-center">
              <i class="feather icon-clipboard mr-1"></i>
              Solicitud
              <strong># {{ modelDetailRequest.idShipmentRequest }}</strong>
            </p>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog.dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <AlertMessageComponent ref="alertMessageComponentModal" />

          <div class="pt-7">
            <div
              v-if="
                userRequest &&
                  withPermissionName(
                    permissions.PRE_REQUEST_APPLICATION_STATUS_03
                  )
              "
              class="col-md-12 p-0 text-right"
            >
              <button
                v-if="modelDetailRequest.statusRequest == 1"
                class="btn btn-outline-danger mr-2"
                type="button"
                @click="
                  setRequestValidation(modelDetailRequest.idShipmentRequest, 2)
                "
              >
                <i class="feather icon-alert-circle mr-1"> </i> Cancelar
                solicitud
              </button>
              <button
                v-if="modelDetailRequest.statusRequest == 1"
                class="btn btn-outline-success"
                type="button"
                @click="
                  setRequestValidation(modelDetailRequest.idShipmentRequest, 3)
                "
              >
                <i class="feather icon-send mr-1"> </i> Enviar para validar
              </button>
            </div>
            <div
              v-if="
                userValidate &&
                  withPermissionName(
                    permissions.PRE_REQUEST_VALIDATOR_EDIT_STATUS_01
                  )
              "
              class="col-md-12 p-0 text-right"
            >
              <button
                v-if="modelDetailRequest.statusRequest == 3"
                @click="setRequest(8)"
                class="btn btn-outline-danger mr-2"
                type="button"
              >
                <i class="feather icon-alert-circle mr-2"></i> Rechazar
              </button>
              <button
                v-if="modelDetailRequest.statusRequest == 6"
                @click="setRequest(9)"
                class="btn btn-outline-danger mr-2"
                type="button"
              >
                <i class="feather icon-alert-circle mr-2"></i> Rechazar
                revalidación
              </button>
              <button
                v-if="modelDetailRequest.statusRequest == 3"
                @click="setRequest(4)"
                class="btn btn-outline-success"
                type="button"
              >
                <i class="feather icon-clipboard mr-2"></i> Enviar para
                autorizar
              </button>
              <button
                v-if="modelDetailRequest.statusRequest == 6"
                @click="setRequest(7)"
                class="btn btn-outline-success"
                type="button"
              >
                <i class="feather icon-clipboard mr-2"></i> Enviar para
                reautorizar
              </button>
            </div>
            <div
              v-if="
                userRequestAuthorization &&
                  withPermissionName(
                    permissions.PRE_REQUEST_AUTHORIZER_EDIT_STATUS_01
                  )
              "
            >
              <div class="row">
                <div class="col-md-6">
                  <button
                    v-if="
                      modelDetailRequest.statusRequest == 4 ||
                        modelDetailRequest.statusRequest == 7
                    "
                    @click="setRequestAuthorization(10)"
                    class="btn btn-outline-danger"
                    type="button"
                  >
                    <i class="feather icon-alert-circle mr-2"></i> Rechazar
                    solicitud
                  </button>
                </div>
                <div
                  v-if="
                    modelDetailRequest.statusRequest == 4 ||
                      modelDetailRequest.statusRequest == 7
                  "
                  class="col-md-6 text-right"
                >
                  <button
                    @click="setRequestAuthorization(6)"
                    class="btn btn-outline-warning mr-2"
                    type="button"
                  >
                    <i class="feather icon-alert-octagon mr-1"></i>
                    Revalidar solicitud
                  </button>
                  <button
                    @click="setRequestAuthorization(5)"
                    class="btn btn-outline-success"
                    type="button"
                  >
                    <i class="feather icon-check-square mr-1"></i>
                    Autorizar solicitud
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-3">
            <div class="col-md-12 pl-8">
              <i class="fa fa-info mr-2 text-fmedical-blue"></i>
              <strong>
                <span> Información de la solicitud</span>
              </strong>
            </div>
            <div class="col-md-12 p-0">
              <div class="card border-light">
                <div class="card-body">
                  <div class="row">
                    <div class="col-xs-12 col-md-6">
                      <i class="fa fa-paperclip mr-2 text-fmedical-blue"></i>
                      <strong>
                        <span> Referencia de la solicitud:</span>
                      </strong>
                      {{ modelDetailRequest.requestKey }}
                    </div>

                    <div class="col-xs-12 col-md-6">
                      <i
                        class="feather icon-clipboard mr-1 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span> Tipo de solicitud:</span>
                      </strong>
                      {{ modelDetailRequest.typeRequestName }}
                    </div>
                    <div class="col-xs-12 col-md-6">
                      <i class="feather icon-truck mr-1 text-fmedical-blue"></i>
                      <strong>
                        <span> Sucursal de aprovisionamiento:</span>
                      </strong>
                      {{ modelDetailRequest.destination.fullName }}
                    </div>
                    <div class="col-xs-12 col-md-6">
                      <i class="feather icon-user mr-1 text-fmedical-blue"></i>
                      <strong>
                        <span> Solicitud generada por:</span>
                      </strong>
                      {{ modelDetailRequest.user.fullName }}
                    </div>
                  </div>
                </div>
                <div class="card-footer card-footer-detail p-1 pl-5">
                  <div class="col-md-12 p-2 pl-0">
                    <ChipTableComponentRequest
                      :styleIcon="true"
                      :status="modelDetailRequest.statusRequest"
                      :statusName="modelDetailRequest.statusRequestName"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              (userRequestAuthorization &&
                (modelDetailRequest.statusRequest == 4 ||
                  modelDetailRequest.statusRequest == 7)) ||
                (userValidate &&
                  (modelDetailRequest.statusRequest == 3 ||
                    modelDetailRequest.statusRequest == 6))
            "
          >
            <template>
              <div class="col-md-12 p-0 pt-5">
                <validation-observer ref="obs1_comment">
                  <form @submit.prevent="form">
                    <div class="row">
                      <div class="col-md-12 pb-0">
                        <validation-provider
                          v-slot="{ errors }"
                          name="comment"
                          rules="required|min:1|max:5000|"
                        >
                          <v-textarea
                            outlined
                            v-model="comments"
                            :error-messages="errors"
                            :label="$t('label.comment')"
                            counter
                            maxlength="5000"
                            rows="4"
                            append-icon="feather icon-message-square"
                            required
                            oninput="this.value = this.value.toUpperCase()"
                          />
                        </validation-provider>
                      </div>
                    </div>
                  </form>
                </validation-observer>
              </div>
            </template>
          </div>

          <div class="pt-5 pb-0">
            <v-alert icon="feather icon-tag" text type="info" outlined
              >Los productos dentro de la sección "Productos dentro del cuadro
              de distribución" están sujetos a cambio sin previo aviso por parte
              del OPD los cuales están basados en solicitudes y consumos
              promedios, en caso de requerir un producto adicional ir a la
              sección de "Productos ajenos al cuadro de distribución" para
              añadirlo.</v-alert
            >
            <template>
              <div class="col-md-12 pl-7 pt-0">
                <i class="feather icon-copy mr-2 text-fmedical-blue"></i>
                <strong>
                  <span> Productos</span>
                </strong>
              </div>

              <div class="col-md-12 p-0">
                <v-tabs
                  class="br"
                  background-color="#263A5B"
                  dark
                  v-model="tab"
                  fixed-tabs
                  icons-and-text
                >
                  <v-tab class="mr-6 pr-7 " href="#1">
                    <v-badge
                      color="primary"
                      dense
                      :content="
                        userRequest || userRequestAuthorization
                          ? modelDetailRequest.orderDistributionProducts ==
                            undefined
                            ? '0'
                            : modelDetailRequest.orderDistributionProducts
                                .length
                          : userValidate
                          ? ordersProductDistribution.length == 0
                            ? '0'
                            : ordersProductDistribution.length
                          : '0'
                      "
                    >
                      <span class="mr-1"
                        >Productos dentro del cuadro de distribución</span
                      >
                    </v-badge>

                    <v-icon class="pb-2">feather icon-clipboard</v-icon>
                  </v-tab>
                  <v-tab class="pr-7" href="#2">
                    <v-badge
                      color="primary"
                      :content="
                        userRequest || userRequestAuthorization
                          ? modelDetailRequest.ordersProduct == undefined
                            ? '0'
                            : modelDetailRequest.ordersProduct.length
                          : userValidate
                          ? ordersProductCnis.length == 0
                            ? '0'
                            : ordersProductCnis.length
                          : '0'
                      "
                    >
                      <span class="mr-1">
                        Productos ajenos al cuadro de distribución</span
                      >
                    </v-badge>

                    <v-icon class="pb-2">feather icon-file-text</v-icon>
                  </v-tab>
                  <v-tab class="pr-4" href="#3">
                    <v-badge
                      color="primary"
                      :content="
                        modelDetailRequest.shipmentRequestComments == undefined
                          ? '0'
                          : modelDetailRequest.shipmentRequestComments.length
                      "
                    >
                      <span class="mr-1"> Comentarios</span>
                    </v-badge>

                    <v-icon class="pb-2">feather icon-message-square</v-icon>
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item value="1" eager>
                    <div class="col-md-12 col-sm-12 pt-0 pl-0 pr-0">
                      <TableRequestProductoComponent
                        :headers="headersDistribution"
                        :dataList="
                          userValidate
                            ? ordersProductDistribution
                            : modelDetailRequest.orderDistributionProducts
                        "
                        :deleteProduct="deleteProductDistribution"
                        :isDelete="
                          addProductDistribution &&
                            userValidate &&
                            (modelDetailRequest.statusRequest == 3 ||
                              modelDetailRequest.statusRequest == 6)
                        "
                        :userValidate="userValidate"
                        :userRequest="userRequest"
                        :userRequestAuthorization="userRequestAuthorization"
                      />
                    </div>
                    <div
                      v-if="
                        addProductDistribution &&
                          (modelDetailRequest.statusRequest == 3 ||
                            modelDetailRequest.statusRequest == 6)
                      "
                      class="col-md-12 col-sm-12 p-0"
                    >
                      <validation-observer
                        ref="observer_add_distribution"
                        v-slot="{ invalid }"
                      >
                        <form @submit.prevent="addDistributionProducts">
                          <div class="row">
                            <div class="col-md-12 pb-0">
                              <validation-provider
                                v-slot="{ errors }"
                                name="products"
                                rules="required"
                              >
                                <v-autocomplete
                                  auto-select-first
                                  :items="productListDistribution"
                                  outlined
                                  v-model="idProductoDistribution"
                                  :label="$t('label.products')"
                                  @change="getProductDistribution()"
                                  no-data-text="Sin resultados"
                                  :error-messages="errors"
                                >
                                  <template slot="item" slot-scope="data">
                                    <div class="pt-5">
                                      {{ data.item.text }}
                                    </div>
                                  </template>
                                  <template slot="selection" slot-scope="data">
                                    <div class="pt-5">
                                      {{ data.item.text }}
                                    </div>
                                  </template>
                                </v-autocomplete>
                              </validation-provider>
                            </div>

                            <div class="col-md-4">
                              <validation-provider
                                v-slot="{ errors }"
                                name="quantity"
                                rules="required|numeric|min_value:1"
                              >
                                <v-text-field
                                  v-model="quantityDistribution"
                                  :counter="8"
                                  outlined
                                  :maxlength="8"
                                  :error-messages="errors"
                                  :label="$t('label.quantity')"
                                  required
                                />
                              </validation-provider>
                            </div>
                            <div class="col-md-4 pt-8">
                              <button
                                class="btn btn-outline-success"
                                type="submit"
                                :disabled="invalid"
                              >
                                <i class="feather icon-plus mr-1"></i>
                                {{ $t("global.add_product") }}
                              </button>
                            </div>
                          </div>
                        </form>
                      </validation-observer>
                    </div>
                  </v-tab-item>
                  <v-tab-item value="2" eager>
                    <div class="col-md-12 col-sm-12 pt-0 pl-0 pr-0">
                      <TableRequestProductoComponent
                        :headers="headers"
                        :dataList="
                          userValidate
                            ? ordersProductCnis
                            : modelDetailRequest.ordersProduct
                        "
                        :isDelete="
                          userValidate &&
                            addProductCnis &&
                            (modelDetailRequest.statusRequest == 3 ||
                              modelDetailRequest.statusRequest == 6)
                        "
                        :userValidate="userValidate"
                        :deleteProduct="deleteProduct"
                        :userRequest="userRequest"
                        :userRequestAuthorization="userRequestAuthorization"
                      />
                      <div
                        v-if="
                          addProductCnis &&
                            (modelDetailRequest.statusRequest == 3 ||
                              modelDetailRequest.statusRequest == 6)
                        "
                        class="col-md-12 col-sm-12 pt-2 pb-0 pl-0 pr-0"
                      >
                        <validation-observer
                          ref="observer_add_cnis"
                          v-slot="{ invalid }"
                        >
                          <form @submit.prevent="addCnisProducts">
                            <div class="row">
                              <div class="col-md-12 pb-0">
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="products"
                                  rules="required"
                                >
                                  <v-autocomplete
                                    auto-select-first
                                    :items="productFormList"
                                    outlined
                                    v-model="idProductoCnis"
                                    :label="$t('label.products')"
                                    no-data-text="Sin resultados"
                                    :error-messages="errors"
                                    @change="getProduct()"
                                  >
                                    <template slot="item" slot-scope="data">
                                      <div class="pt-5">
                                        {{ data.item.text }}
                                      </div>
                                    </template>
                                    <template
                                      slot="selection"
                                      slot-scope="data"
                                    >
                                      <div class="pt-5">
                                        {{ data.item.text }}
                                      </div>
                                    </template>
                                  </v-autocomplete>
                                </validation-provider>
                              </div>

                              <div class="col-md-4">
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="quantity"
                                  rules="required|numeric|min_value:1"
                                >
                                  <v-text-field
                                    v-model="quantityCnis"
                                    :counter="8"
                                    outlined
                                    :maxlength="8"
                                    :error-messages="errors"
                                    :label="$t('label.quantity')"
                                    required
                                  />
                                </validation-provider>
                              </div>
                              <div class="col-md-4 pt-8">
                                <button
                                  class="btn btn-outline-success"
                                  type="submit"
                                  :disabled="invalid"
                                >
                                  <i class="feather icon-plus mr-1"></i>
                                  {{ $t("global.add_product") }}
                                </button>
                              </div>
                            </div>
                          </form>
                        </validation-observer>
                      </div>
                    </div>
                  </v-tab-item>
                  <v-tab-item value="3">
                    <div class="col-md-12 col-sm-12 pb-0 pt-0 pl-0 pr-0">
                      <HistoryCommentComponent
                        :tab="tab"
                        :comment="modelDetailRequest.shipmentRequestComments"
                      />
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </template>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
/**
 * @component ModalRequestComponent
 * @desc Este es el componente ModalRequestComponent que se utiliza para el detalle de la solicitud, en donde se desplienga las
 * principales funcionales de solicitudes que son tales como, validar, autorizar, rechazar, cancelar las solicitudes asi como agregar e eliminar productos
 * @author Jesus Teran
 */
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { SwalConfirm, SwalDelete } from "@/core/SwalAlert";
import TableRequestProductoComponent from "@/components/shipments-request/TableRequestProductoComponent";
import {
  PRERESQUEST_PRODUCT_DISTRIBUTION,
  PRERESQUEST_PRODUCT_CNIS,
  PRERESQUEST_PRODUCT_CNIS_AUTORIZE,
} from "@/core/DataTableHeadersLocale";
import HistoryCommentComponent from "@/components/shipments-request/HistoryCommentComponent";
import { PreRequest } from "@/core/request";
import { UtilFront, PermissionConstants } from "@/core";
import { mapState, mapActions } from "vuex";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import ChipTableComponentRequest from "@/common/table/ChipTableComponentRequest";

export default {
  /** @vprop {Array} [productListDistribution={}] - Lista de los productos del cuadro de distribución */
  /** @vprop {Array} [productFormList={}] - Lista de todos los productos */
  /** @vprop {Object} [modelDetailRequest={}] - Informacion de la solicitud  */
  /** @vprop {Function} [changeAction={}] - Función para emitir el cambio de vista o modulo en la vista principal de solicitudes */
  /** @vprop {Object} [dialog={}] - Objecto para mostrar y ocultar el modal */
  /** @vprop {Function} [alert={}] - Funcion para mostrar la alerta en la vista principal, mensaje de exito o error. */
  /** @vprop {Object} [persistent={}] - Para que el modal percista y no se oculte (Esto evita un problema con SweetAlert2 al ocultar las alertas) */
  /** @vprop {Function} [setRequestValidation={}] - Metodo para enviar la solicitud a validar (Solo utilizado en el submodulo de solicitudes) */

  /** @vprop {Boolean} [saveRequest='false'] - Legacy */
  /** @vprop {Boolean} [addProductDistribution='false'] - Valida el permiso en la vista para permitir al usuario agregar productos al cuadro de distribución */
  /** @vprop {Boolean} [addProductCnis='false'] - Valida el permiso en la vista para permitir al usuario agregar productos al cuadro de distribución */
  /** @vprop {Boolean} [userValidate='false'] - Valida el tipo de usuario en este caso si es el usuario tipo Validador */
  /** @vprop {Boolean} [userRequest='false'] - Valida el tipo de usuario en este caso si es el usuario tipo Solicitante */
  /** @vprop {Boolean} [userRequestAuthorization='false'] - Valida el tipo de usuario en este caso si es el usuario tipo Autorizador */
  props: {
    productListDistribution: Array,
    productFormList: Array,
    modelDetailRequest: Object,
    changeAction: Function,
    dialog: Object,
    alert: Function,
    persistent: Object,
    setRequestValidation: Function,
    saveRequest: false,
    addProductDistribution: false,
    addProductCnis: false,
    userValidate: false,
    userRequest: false,
    userRequestAuthorization: false,
  },
  data() {
    return {
      permissions: PermissionConstants,
      loading: false,

      tab: "1", // Variable para el cambio de pestañas
      headers:
        this.userRequestAuthorization || this.userRequest
          ? PRERESQUEST_PRODUCT_CNIS_AUTORIZE()
          : PRERESQUEST_PRODUCT_CNIS(),
      headersDistribution: PRERESQUEST_PRODUCT_DISTRIBUTION(),
      /** * @member {String} comments Guarda el comentario de la solicitud */
      comments: "",
      /** * @member {String} idProductoCnis Guarda el id del producto CNIS */
      /** * @member {String} quantityCnis Guarda la cantidad asiganada al producto seleccionad en cuadro de distribución */
      idProductoCnis: "",
      quantityCnis: "",
      /** * @member {Array} ordersProductUpdate Guarda la lista de los productos ajenos al cuadro de distribución que se estan modificando */
      /** * @member {Array} ordersProductCnis Guarda la lista de los productos ajenos al cuadro de distribución que se estan modificando */
      ordersProductUpdate: [],
      ordersProductCnis: [],
      /**
       * @prop {Object} products Guarda el producto ajenos al cuadro de distribución
       * @prop {String} products.idProduct Guarda id del producto seleccionad
       * @prop {String} products.productKey Guarda el (key) para la comparación del producto
       * @prop {String} products.text Guarda el nombre del producto seleccionado
       */
      products: {
        idProduct: "",
        productKey: "",
        text: "",
      },
      /* Variables para productos de CNIS */
      /** * @member {Array} ordersProductUpdateDistribution Guarda la lista de los productos cuadro de distribución que se estan modificando */
      ordersProductUpdateDistribution: [],
      /** * @member {Array} ordersProductDistribution Guarda la lista de los productos cuadro de distribución que se estan modificando pero esta se muestra en la tabla */
      ordersProductDistribution: [],
      /** * @member {String} idProductoDistribution Guarda el id del producto en cuadro de distribución seleccionado */
      /** * @member {String} quantityDistribution Guarda la cantidad asiganada al producto seleccionad en cuadro de distribución */
      idProductoDistribution: "",
      quantityDistribution: "",
      /**
       * @prop {Object} productsDistribution Guarda el producto seleccionado en el cuadro de distribución
       * @prop {String} productsDistribution.value Guarda id del producto seleccionad
       * @prop {String} productsDistribution.text Guarda el nombre del producto seleccionado
       */
      productsDistribution: {
        value: "",
        text: "",
      },
    };
  },
  computed: {
    /**
     * @computed {mapState} remission{listProduct]
     */
    ...mapState("remission", ["listProduct"]),
    /**
     *@computed {mapState} auth{branchOfficeState}
     */
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
  },
  methods: {
    ...mapActions({ getListProducts: "remission/getListProducts" }),
    alertModal(type, message) {
      this.$refs.alertMessageComponentModal.reloadComponent(type, message);
    },
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    /**
     * Ejecuta el servicio para guardar la solicitud creada
     * @method
     * @param {Number} statusRequest - Valida el estado de la solicitud a la que sera enviada Ej: 10 = Rechazada
     */
    async setRequestAuthorization(statusRequest) {
      var isValid = await this.$refs.obs1_comment.validate();
      if (isValid) {
        this.persistent.persistent = true;
        const { isConfirmed } = await SwalConfirm.fire({
          text:
            statusRequest == 10
              ? "La solicitud será rechazada."
              : statusRequest == 6
              ? "La solicitud será enviada para revalidar."
              : "La solicitud será autorizada.",
          icon:
            statusRequest == 10
              ? "warning"
              : statusRequest == 6
              ? "info"
              : "question",
        });
        if (!isConfirmed) {
          return;
        }
        let saveRequest = {
          userBranchOffice: {
            idUser: UtilFront.getDataUser().idUser,
            idBranchOffice: this.branchOfficeState.idBranchoffice,
          },
          idShipmentRequest: this.modelDetailRequest.idShipmentRequest,
          requestKey: this.modelDetailRequest.requestKey,
          comments: this.comments,
          statusRequest: statusRequest,
          idOrder: this.modelDetailRequest.idOrder,
          typeRequest: this.modelDetailRequest.typeRequest,
          destination: {
            idBranchOffice: this.modelDetailRequest.destination.idBranchOffice,
          },
          typeUser: 3,
          ordersProduct: [],
          statusRequestUser: 0,
        };
        PreRequest.setUpdateRequestValidation(saveRequest)
          .then((response) => {
            let { success, message } = response.data.data;
            if (success) {
              this.changeAction(1, true);
              this.alert(
                "success",
                statusRequest == 10
                  ? "Succces_Remmision_Status_0007"
                  : statusRequest == 6
                  ? "Succces_Remmision_Status_0005"
                  : "Succces_Remmision_Status_0006"
              );
              this.dialog.dialog = false;
            } else {
              this.alertModal("error", message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error(error);
          })
          .finally(() => {});
      }
    },
    async setRequest(statusRequest) {
      var isValid = await this.$refs.obs1_comment.validate();
      if (isValid) {
        const productDistribution = await this.getProductBoard();
        const productCnis = await this.getProductCnis();
        if (!(productCnis.length == 0 && productDistribution.length == 0)) {
          this.updateRequest(productCnis, productDistribution, statusRequest);
          return;
        }
        this.alert("error", "Error_Product_Add_0001");
      }
    },
    async updateRequest(productCnis, productDistribution, statusRequest) {
      this.persistent.persistent = true;
      let ordersProduct = productCnis.concat(productDistribution);
      const { isConfirmed } = await SwalConfirm.fire({
        html:
          statusRequest == 8
            ? "La solicitud con el identificador " +
              "<div><b>" +
              this.modelDetailRequest.idShipmentRequest +
              "</b>" +
              " será rechazada en validación."
            : statusRequest == 4
            ? "La solicitud será enviada para autorización."
            : statusRequest == 9
            ? "La solicitud con el identificador " +
              "<div><b>" +
              this.modelDetailRequest.idShipmentRequest +
              "</b>" +
              " será rechazada en revalidación."
            : "La solicitud con el identificador " +
              "<div><b>" +
              this.modelDetailRequest.idShipmentRequest +
              "</b>" +
              " será enviada para reautorizar.",
        icon:
          statusRequest == 8
            ? "warning"
            : statusRequest == 4
            ? "question"
            : statusRequest == 9
            ? "warning"
            : "info",
      });
      if (!isConfirmed) {
        return;
      }
      let saveRequest = {
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOfficeState.idBranchoffice,
        },
        idShipmentRequest: this.modelDetailRequest.idShipmentRequest,
        requestKey: this.modelDetailRequest.requestKey,
        comments: this.comments,
        statusRequest: statusRequest,
        idOrder: this.modelDetailRequest.idOrder,
        typeRequest: this.modelDetailRequest.typeRequest,
        destination: {
          idBranchOffice: this.modelDetailRequest.destination.idBranchOffice,
        },
        typeUser: 2,
        ordersProduct: ordersProduct,
        statusRequestUser: 0,
      };
      PreRequest.setUpdateRequestValidation(saveRequest)
        .then((response) => {
          let { success, message } = response.data.data;
          if (success) {
            this.changeAction(1, true);
            this.alert(
              "success",
              statusRequest == 8 || statusRequest == 9
                ? "Succces_Remmision_Status_0007"
                : statusRequest == 4
                ? "Succces_Remmision_Status_0003"
                : "Succces_Remmision_Status_0004"
            );
            this.dialog.dialog = false;
          } else {
            this.alertModal("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(error);
        })
        .finally(() => {});
    },
    async getProductBoard() {
      let productDistribution = [];
      this.ordersProductUpdateDistribution.map((product) => {
        let products = {
          distributionBoardProducts: {
            idDistributionProduct: product.products.idProduct,
          },
          idOrderProduct: product.idOrderProduct,
          quantityOrdersProduct: parseInt(product.quantityOrdersProduct),
          action: product.action == undefined ? 0 : product.action,
        };
        productDistribution.push(products);
      });
      return productDistribution;
    },
    async getProductCnis() {
      let productCnis = [];
      this.ordersProductUpdate.map((product) => {
        let products = {
          products: {
            idProduct: product.products.idProduct,
          },
          idOrderProduct: product.idOrderProduct,
          quantityOrdersProduct: parseInt(product.quantityOrdersProduct),
          action: product.action == undefined ? 0 : product.action,
        };
        productCnis.push(products);
      });
      return productCnis;
    },

    async deleteProduct(item) {
      this.persistent.persistent = true;
      const { isConfirmed } = await SwalDelete.fire({
        title: this.$t("shipment_request.title_delete"),
        html:
          '<div class="pb-2"><b>' +
          this.$t("shipment_request.quantity") +
          "</b>" +
          " " +
          item.quantityOrdersProduct +
          "</br></div>" +
          "<div ><b>" +
          this.$t("shipment_request.description") +
          "</b>" +
          " " +
          item.products.name +
          "</div>",
      });
      if (!isConfirmed) {
        return;
      }
      let delete_product = false;
      this.ordersProductUpdate = this.ordersProductUpdate.map((element) => {
        if (element.products.idProduct == item.products.idProduct) {
          if (element.action == 1) {
            delete_product = true;
          }
          element.action = 3;
          element.quantityOrdersProduct = 0;
        }
        return element;
      });
      if (delete_product) {
        this.ordersProductUpdate = this.ordersProductUpdate.filter(
          (element) => element.products.idProduct !== item.products.idProduct
        );
      }
    },
    async deleteProductDistribution(item) {
      this.persistent.persistent = true;
      const { isConfirmed } = await SwalDelete.fire({
        title: this.$t("shipment_request.title_delete"),
        html:
          '<div class="pb-2"><b>' +
          this.$t("shipment_request.quantity") +
          "</b>" +
          " " +
          item.quantityOrdersProduct +
          "</br></div>" +
          "<div ><b>" +
          this.$t("shipment_request.description") +
          "</b>" +
          " " +
          item.products.description +
          "</div>",
      });
      if (!isConfirmed) {
        return;
      }
      let delete_product = false;
      this.ordersProductUpdateDistribution = this.ordersProductUpdateDistribution.map(
        (element) => {
          if (element.products.idProduct == item.products.idProduct) {
            if (element.action == 1) {
              delete_product = true;
            }
            element.action = 3;
            element.quantityOrdersProduct = 0;
          }
          return element;
        }
      );
      if (delete_product) {
        this.ordersProductUpdateDistribution = this.ordersProductUpdateDistribution.filter(
          (element) => element.products.idProduct !== item.products.idProduct
        );
      }
    },
    addDistributionProducts() {
      let product_exists = false;
      if (this.ordersProductUpdateDistribution.length > 0) {
        this.ordersProductUpdateDistribution = this.ordersProductUpdateDistribution.map(
          (element) => {
            if (
              element.products.idProduct == this.productsDistribution[0].value
            ) {
              element.quantityOrdersProduct =
                parseInt(element.quantityOrdersProduct) +
                parseInt(this.quantityDistribution);
              element.action == 1 ? (element.action = 1) : (element.action = 2);
              product_exists = true;
            }
            return element;
          }
        );
      }
      if (!product_exists) {
        this.ordersProductUpdateDistribution = [
          ...this.ordersProductUpdateDistribution,
          {
            products: {
              idProduct: this.productsDistribution[0].value,
              description: this.productsDistribution[0].text,
            },
            action: 1,
            quantityOrdersProduct: parseInt(this.quantityDistribution),
            statusOrdersProduct: 1,
          },
        ];
      }
      this.idProductoDistribution = this.quantityDistribution = "";
      this.productsDistribution = {
        value: "",
        de: "",
        text: "",
      };
      this.$refs.observer_add_distribution.reset();
    },
    addCnisProducts() {
      let product_exists = false;
      if (this.ordersProductUpdate.length > 0) {
        this.ordersProductUpdate = this.ordersProductUpdate.map((element) => {
          if (element.products.idProduct == this.products[0].idProduct) {
            element.quantityOrdersProduct =
              parseInt(element.quantityOrdersProduct) +
              parseInt(this.quantityCnis);
            element.action == 1 ? (element.action = 1) : (element.action = 2);
            product_exists = true;
          }
          return element;
        });
      }
      if (!product_exists) {
        if (this.ordersProductUpdate.length > 150) {
          this.$emit("alert", "error", "Message_Branch_0002");
        } else {
          this.ordersProductUpdate = [
            ...this.ordersProductUpdate,
            {
              products: {
                idProduct: this.products[0].idProduct,
                productKey: this.products[0].productKey,
                name: this.products[0].description,
              },
              action: 1,
              quantityOrdersProduct: parseInt(this.quantityCnis),
              statusOrdersProduct: 1,
            },
          ];
        }
      }
      this.idProductoCnis = this.quantityCnis = "";
      this.products = {
        value: "",
        productKey: "",
        text: "",
      };
      this.$refs.observer_add_cnis.reset();
    },
    getProduct() {
      this.products = this.listProduct.productServerList.filter((element) => {
        return element.idProduct === this.idProductoCnis;
      });
    },
    getProductDistribution() {
      this.productsDistribution = this.productListDistribution.filter(
        (element) => {
          return element.value == this.idProductoDistribution;
        }
      );
    },
    async orderObjectUpdate(ordersProductCnis) {
      let product = [];
      if (ordersProductCnis != undefined) {
        ordersProductCnis.map((result) => {
          let productItem = {
            products: {
              idProduct: result.products.idProduct,
              productKey: result.products.productKey,
              name: result.products.description,
            },
            idOrderProduct: result.idOrderProduct,
            quantityOrdersProduct: result.quantityOrdersProduct,
            statusOrdersProduct: 1,
          };
          product.push(productItem);
        });
      }
      return product;
    },
    async orderObjectUpdateDistribution(ordersProductDistribution) {
      let product = [];
      if (ordersProductDistribution != undefined) {
        ordersProductDistribution.map((result) => {
          let productItem = {
            products: {
              idProduct: result.distributionBoardProducts.idDistributionProduct,
              description: result.products.description,
            },
            idOrderProduct: result.idOrderProduct,
            quantityOrdersProduct: result.quantityOrdersProduct,
            statusOrdersProduct: 1,
          };
          product.push(productItem);
        });
      }
      return product;
    },
  },
  watch: {
    async modelDetailRequest() {
      this.tab = "1";
      this.comments = "";
      if (this.userValidate) {
        this.ordersProductDistribution = this.ordersProductUpdateDistribution = await this.orderObjectUpdateDistribution(
          this.modelDetailRequest.orderDistributionProducts
        );
        this.ordersProductCnis = this.ordersProductUpdate = await this.orderObjectUpdate(
          this.modelDetailRequest.ordersProduct
        );
        if (this.listProduct.productFormList == undefined) {
          this.loading = UtilFront.getLoadding();
          await this.getListProducts();
          this.loading.hide();
        } else {
          this.productFormList = this.listProduct.productFormList;
        }
      }
    },
    ordersProductUpdateDistribution() {
      this.ordersProductDistribution = this.ordersProductUpdateDistribution.filter(
        (element) => element.action !== 3
      );
    },
    ordersProductUpdate() {
      this.ordersProductCnis = this.ordersProductUpdate.filter(
        (element) => element.action !== 3
      );
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    TableRequestProductoComponent,
    HistoryCommentComponent,
    AlertMessageComponent,
    ChipTableComponentRequest,
  },
};
</script>
