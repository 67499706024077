<template>
  <div class="row">
    <div class="col-md-12 pb-10 ">
      <div class="pt-5">
        <div class="col-md-12 pl-8 ">
          <i class="fa fa-info mr-2 text-fmedical-blue"></i>
          <strong>
            <span> Historial de comentarios</span>
          </strong>
        </div>
        <div
          class="card border-light "
          v-bind:class="[comment == undefined ? '' : 'card-comment', '']"
        >
          <div
            ref="container"
            v-bind:class="[comment == undefined ? '' : 'card-body-comment', '']"
          >
            <div v-if="comment == undefined">
              <div class="col-md-12 text-center pt-5">
                <h3>LA SOLICITUD AUN NO TIENE COMENTARIOS</h3>
                <v-icon>feather icon-bell-off</v-icon>
              </div>
            </div>
            <div class="chat" v-else>
              <div class="chat-history">
                <ul class="m-b-0" v-for="(comment, key) in comment" :key="key">
                  <div class="col-md-12 p-1">
                    <div v-if="user == comment.user.idUser">
                      <li class="clearfix chat">
                        <div class="text-right mr-40 pb-0">
                          <span class="message-data-time"
                            ><span class="date">
                              {{ comment.dateComment }}</span
                            >
                            |
                            <strong>{{ comment.user.fullName }}</strong>
                          </span>
                        </div>
                        <div class="message other-message ml-12 float-right">
                          {{ comment.comment }}
                        </div>
                        <div class="col-md-12 pt-1 pb-0 mr-0 float-right">
                          <ChipTableComponentRequest
                            class="mr-0 float-right"
                            :styleIconChat="true"
                            :status="comment.statusComment"
                            :statusName="comment.statusName"
                          />
                        </div>
                      </li>
                    </div>
                    <div v-else>
                      <li class="clearfix chat">
                        <div class="message-data">
                          <span class="message-data-time">
                            <strong class="date-user"
                              >{{ comment.user.fullName }}
                            </strong>
                            |
                            <span class="date"> {{ comment.dateComment }}</span>
                          </span>
                        </div>
                        <div class="message my-message mr-12 ">
                          {{ comment.comment }}
                        </div>
                        <div class="col-md-12 pt-1 pb-0 mr-0 float-left">
                          <ChipTableComponentRequest
                            class="mr-0 float-left"
                            :styleIconChat="true"
                            :status="comment.statusComment"
                            :statusName="comment.statusName"
                          />
                        </div>
                      </li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChipTableComponentRequest from "@/common/table/ChipTableComponentRequest";
import { UtilFront } from "@/core";
export default {
  props: {
    comment: Array,
    tab: false,
  },
  data() {
    return {
      user: 0,
      method: false,
    };
  },
  computed: {},
  methods: {
    scrollToEnd() {
      var content = this.$refs.container;
      content.scrollTop = content.scrollHeight;
    },
  },
  created() {
    if (!(this.comment == undefined)) {
      this.comment.reverse();
    }
    this.user = UtilFront.getDataUser().idUser;
  },
  mounted() {
    this.$nextTick(() => {
      var content = this.$refs.container;
      content.scrollTop = content.scrollHeight;
    });
  },
  components: {
    ChipTableComponentRequest,
  },
};
</script>

<style lang="scss" scoped>
.card-comment {
  max-height: 550px !important;
  padding-bottom: 20px;
}

.chat .chat-history {
  padding: 10px;
}

.chat .chat-history .message {
  padding: 6px 20px 6px 20px;
  border-radius: 14px;
  display: inline-block;
  position: relative;
}

.chat .date-user {
  color: #67686e;
}

.chat .date {
  font-size: 13px;
  color: #7a7c83;
}

.chat .chat-history .my-message {
  border: 1px solid rgb(222, 226, 230);
  border-radius: 15px;
  padding-right: 20px;
}

.chat .chat-history .other-message {
  background: #007aff;
  text-align: left;
  color: #fff;
}
.chat .chat-history .message-data-time {
  color: #434651;
  padding-left: 15px;
}

li {
  list-style: none;
}

.card-body-comment {
  height: 550px;
  max-height: 550px;
  overflow-y: scroll;
}
</style>
