<template>
  <div class="col-md-12">
    <v-alert class="br" icon="feather icon-tag" text type="info" outlined
      >Los productos dentro de la sección "Productos dentro del cuadro de
      distribución" están sujetos a cambio sin previo aviso por parte del OPD
      los cuales están basados en solicitudes y consumos promedios, en caso de
      requerir un producto adicional ir a la sección de "Productos ajenos al
      cuadro de distribución" para añadirlo.</v-alert
    >
    <v-tabs
      class="br"
      background-color="#263A5B"
      dark
      v-model="tab"
      fixed-tabs
      icons-and-text
    >
      <v-tab href="#1">
        <v-badge color="primary" :content="counterProducts">
          <span class="mr-1">Productos dentro del cuadro de distribución</span>
        </v-badge>

        <v-icon class="pb-2">feather icon-clipboard</v-icon>
      </v-tab>
      <v-tab href="#2">
        <v-badge
          color="primary"
          :content="
            productList.productListCnis.length == 0
              ? '0'
              : productList.productListCnis.length
          "
        >
          <span class="mr-1">Productos ajenos al cuadro de distribución</span>
        </v-badge>

        <v-icon class="pb-2">feather icon-file-text</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="1" eager>
        <TableProductAddDistributionComponent
          :headers="headersDistributionProducts"
          :dataList="productListDistributionTable"
        />
      </v-tab-item>

      <v-tab-item value="2" eager>
        <TableProductAddComponent
          :headers="headersCnisProducts"
          :dataList="productList.productListCnis"
          :deleteProductList="deleteProductList"
        />
        <validation-observer ref="observer_add" v-slot="{ invalid }">
          <form class="p-2" @submit.prevent="addProductCnis">
            <div class="row">
              <div class="col-md-12 pt-9">
                <validation-provider
                  v-slot="{ errors }"
                  name="product"
                  rules="required"
                >
                  <v-autocomplete
                    :error-messages="errors"
                    auto-select-first
                    :items="productFormList"
                    outlined
                    class="br"
                    v-model="itemProduct"
                    @change="getProduct()"
                    :label="$t('label.product')"
                  >
                    <template slot="item" slot-scope="data">
                      <div class="pt-5">
                        {{ data.item.text }}
                      </div>
                    </template>

                    <template slot="selection" slot-scope="data">
                      <div class="pt-5">
                        {{ data.item.text }}
                      </div>
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </div>

              <div class="col-md-4">
                <validation-provider
                  v-slot="{ errors }"
                  name="quantity"
                  rules="required|numeric|min_value:1"
                >
                  <v-text-field
                    v-model="quantity"
                    :counter="8"
                    outlined
                    dense
                    class="br"
                    :maxlength="8"
                    :error-messages="errors"
                    :label="$t('label.quantity')"
                    required
                  />
                </validation-provider>
              </div>
              <div class="col-md-4 pt-4">
                <button
                  class="btn btn-outline-success"
                  type="submit"
                  :disabled="invalid"
                >
                  <i class="feather icon-plus mr-1"></i>
                  {{ $t("global.add_product") }}
                </button>
              </div>
            </div>
          </form>
        </validation-observer>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import TableProductAddComponent from "@/components/shipments-request/request/TableProductAddComponent";
import TableProductAddDistributionComponent from "@/components/shipments-request/request/TableProductAddDistributionComponent";
import {
  PRODUCT_PREREQUEST,
  PRODUCT_PREREQUEST_DISTRIBUTION,
} from "@/core/DataTableHeadersLocale";
import { mapState, mapMutations, mapActions } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { UtilFront } from "@/core";
export default {
  props: {
    productList: Object,
    modelRequest: Object,
    productListDistributionTable: Array,
  },
  data() {
    return {
      tab: "1",
      headersCnisProducts: PRODUCT_PREREQUEST(),
      headersDistributionProducts: PRODUCT_PREREQUEST_DISTRIBUTION(),
      quantity: "",
      itemProduct: "",
      productFormList: [],
      products: {
        idProduct: "",
        productKey: "",
        text: "",
      },
    };
  },
  computed: {
    counterProducts() {
      let counter = "0";
      this.productListDistributionTable.map((product) => {
        if (!(product.quantity == undefined || product.quantity == ""))
          counter++;
      });
      return counter;
    },
    ...mapState("remission", ["listProduct"]),
  },
  methods: {
    ...mapActions({ getListProducts: "remission/getListProducts" }),
    ...mapMutations({
      listAllProducts: "remission/GET_PRODUCTS",
    }),
    getProduct() {
      this.products = this.listProduct.productServerList.filter((element) => {
        return element.idProduct === this.itemProduct;
      });
    },
    addProductCnis() {
      let product_exists = false;
      if (this.productList.productListCnis.length > 0) {
        this.productList.productListCnis = this.productList.productListCnis.map(
          (element) => {
            if (element.products.idProduct == this.products[0].idProduct) {
              element.quantityOrdersProduct =
                parseInt(element.quantityOrdersProduct) +
                parseInt(this.quantity);
              product_exists = true;
            }
            return element;
          }
        );
      }
      if (!product_exists) {
        if (this.productList.productListCnis.length > 150) {
          this.$emit("alert", "error", "Message_Branch_0002");
        } else {
          this.productList.productListCnis = [
            ...this.productList.productListCnis,
            {
              products: {
                idProduct: this.products[0].idProduct,
                productKey: this.products[0].productKey,
                name: this.products[0].description,
              },
              action: 1,
              quantityOrdersProduct: parseInt(this.quantity),
              statusOrdersProduct: 1,
            },
          ];
        }
      }
      this.itemProduct = this.quantity = "";
      this.products = {
        idProduct: "",
        productKey: "",
        text: "",
      };
      this.$refs.observer_add.reset();
    },
    deleteProductList(item) {
      if (this.modelRequest.idShipmentRequest !== "") {
        let delete_product = false;
        this.ordersProductUpdate = this.ordersProductUpdate.map((element) => {
          if (element.products.idProduct == item.products.idProduct) {
            if (element.action == 1) {
              delete_product = true;
            }
            element.action = 3;
            element.quantityOrdersProduct = 0;
          }
          return element;
        });
        if (delete_product) {
          this.ordersProductUpdate = this.ordersProductUpdate.filter(
            (element) => element.products.idProduct !== item.products.idProduct
          );
        }
      } else {
        this.productList.productListCnis = this.productList.productListCnis.filter(
          (element) => element.products.idProduct !== item.products.idProduct
        );
      }
    },
  },
  async created() {
    if (this.listProduct.productFormList == undefined) {
      this.loading = UtilFront.getLoadding();
      await this.getListProducts();
      this.loading.hide();
    } else {
      this.productFormList = this.listProduct.productFormList;
    }
  },
  watch: {
    listProduct() {
      this.productFormList = this.listProduct.productFormList;
    },
  },
  components: {
    TableProductAddComponent,
    TableProductAddDistributionComponent,
    ValidationProvider,
    ValidationObserver,
  },
};
</script>
