<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :items="dataList"
      :search="search"
      height="auto"
      fixed-header
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
    >
      <template v-slot:top>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <v-text-field
              v-model="search"
              :label="$t('table.search')"
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip v-if="isPDF" top>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand text-fmedical-red"
              @click="downloadPDF(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-file-text mr-2"></i>
            </span>
          </template>
          <span>{{ $t("table.download_PDF") }}</span>
        </v-tooltip>
        <v-tooltip
          top
          v-if="
            isDetail &&
              isUpdate &&
              userRequest &&
              item.statusRequest == 1 &&
              false
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-2"
              @click="updateRequest(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-edit-1"></i>
            </span>
          </template>
          <span>{{ $t("table.edit") }}</span>
        </v-tooltip>
        <v-tooltip
          top
          v-if="
            userRequestAuthorization &&
              isDetail &&
              (item.statusRequest == 4 || item.statusRequest == 7)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-2"
              @click="assingUserRequest(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-clipboard"></i>
            </span>
          </template>
          <span>{{ item.statusRequestName }}</span>
        </v-tooltip>
        <v-tooltip
          top
          v-else-if="userRequest && isDetail && item.statusRequest == 1"
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-2"
              @click="detailRequest(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-clipboard"></i>
            </span>
          </template>
          <span>{{ item.statusRequestName }}</span>
        </v-tooltip>

        <v-tooltip
          top
          v-else-if="
            userValidate &&
              isDetail &&
              (item.statusRequest == 3 || item.statusRequest == 6)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-2"
              @click="assingUserRequest(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-clipboard"></i>
            </span>
          </template>
          <span>{{ item.statusRequestName }}</span>
        </v-tooltip>
        <v-tooltip
          top
          v-if="
            userRequest &&
              isDetail &&
              (item.statusRequest == 2 ||
                item.statusRequest == 3 ||
                item.statusRequest == 4 ||
                item.statusRequest == 5 ||
                item.statusRequest == 6 ||
                item.statusRequest == 7 ||
                item.statusRequest == 8 ||
                item.statusRequest == 9 ||
                item.statusRequest == 10)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <span class="hand mr-2" v-bind="attrs" v-on="on">
              <i @click="detailRequest(item)" class="feather icon-eye"></i>
            </span>
          </template>
          <span>Detalle</span>
        </v-tooltip>
        <v-tooltip
          top
          v-if="
            userValidate &&
              isDetail &&
              (item.statusRequest == 7 ||
                item.statusRequest == 8 ||
                item.statusRequest == 9 ||
                item.statusRequest == 5 ||
                item.statusRequest == 4 ||
                item.statusRequest == 10)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <span class="hand mr-2" v-bind="attrs" v-on="on">
              <i @click="detailRequest(item)" class="feather icon-eye"></i>
            </span>
          </template>
          <span>Detalle</span>
        </v-tooltip>
        <v-tooltip
          top
          v-if="
            userRequestAuthorization &&
              isDetail &&
              (item.statusRequest == 5 ||
                item.statusRequest == 6 ||
                item.statusRequest == 8 ||
                item.statusRequest == 9 ||
                item.statusRequest == 10)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <span class="hand mr-2" v-bind="attrs" v-on="on">
              <i @click="detailRequest(item)" class="feather icon-eye"></i>
            </span>
          </template>
          <span>Detalle</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.statusRequestName`]="{ item }">
        <ChipTableComponentRequest
          :status="item.statusRequest"
          :statusName="item.statusRequestName"
        />
      </template>
    </v-data-table>
    <ButtonPrintComponent />
  </div>
</template>

<script>
/**
 * @component TableRequestComponent
 * @desc Este es el componente de TableRequestComponent es la tabla principal de los sub-modulo de solicitudes que se utiliza en las vistas principales del modulo.
 * @author Jesus Teran
 */

import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";
import ButtonTableComponent from "@/common/table/ButtonTableComponent";
import ChipTableComponentRequest from "@/common/table/ChipTableComponentRequest";
import { PreRequest } from "@/core/request";

export default {
  /** @vprop {Object} [headers={}] - Recibe la cabecera de la tabla  */
  /** @vprop {Object} [dataList='null'] - Recibe la lista de solicitudes para mostrarlas en la tabla */
  /** @vprop {Boolean} [isUpdate='false'] - Valida el permiso para realizar la modificación de la solicitud */
  /** @vprop {Boolean} [isPDF='false'] - Valida el permiso para mostrar el icono de descargar PDF */
  /** @vprop {Boolean} [isDetail='false'] - Valida el permiso para mostrar el icono del detalle de la solicitud */
  /** @vprop {Boolean} [userValidate='false'] - Valida el permiso para validar la solicitud */
  /** @vprop {Boolean} [userRequest='false'] - Valida el permiso para enviar la solicitud a validar  */
  /** @vprop {Boolean} [userRequestAuthorization='false'] - Valida el permiso para autorizar la solicitud */
  props: {
    headers: {},
    dataList: null,
    isUpdate: false,
    isPDF: false,
    isDetail: false,
    userValidate: false,
    userRequest: false,
    userRequestAuthorization: false,
    /* Fuction Request */
    detailRequest: Function,
    assingUserRequest: Function,
    updateRequest: Function,
  },
  data() {
    return {
      /** * @member {String} */
      search: "",
    };
  },
  methods: {
    /**
     * @method
     * @desc Emite la function excelTable para ejecutar la llama al servicio y generar el archivo.xlsx
     */
    excelTable() {
      this.$emit("excelTable");
    },
    /**
     * @method
     * @desc Realizar la llamada al API para generar el documento PDF
     * @param {Object} item
     * @param {Number} item.idOrder Valor de la orden de la solicitud
     * @param {Number} item.idShipmentRequest Identificador de la solicitud
     */
    downloadPDF(item) {
      let data =
        "orderId=" + item.idOrder + "&idShipment=" + item.idShipmentRequest;
      PreRequest.reportPreRequest(data);
    },
  },
  components: {
    ButtonPrintComponent,
    ButtonTableComponent,
    ChipTableComponentRequest,
  },
};
</script>
