var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c('v-alert',{staticClass:"br",attrs:{"icon":"feather icon-tag","text":"","type":"info","outlined":""}},[_vm._v("Los productos dentro de la sección \"Productos dentro del cuadro de distribución\" están sujetos a cambio sin previo aviso por parte del OPD los cuales están basados en solicitudes y consumos promedios, en caso de requerir un producto adicional ir a la sección de \"Productos ajenos al cuadro de distribución\" para añadirlo.")]),_c('v-tabs',{staticClass:"br",attrs:{"background-color":"#263A5B","dark":"","fixed-tabs":"","icons-and-text":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#1"}},[_c('v-badge',{attrs:{"color":"primary","content":_vm.counterProducts}},[_c('span',{staticClass:"mr-1"},[_vm._v("Productos dentro del cuadro de distribución")])]),_c('v-icon',{staticClass:"pb-2"},[_vm._v("feather icon-clipboard")])],1),_c('v-tab',{attrs:{"href":"#2"}},[_c('v-badge',{attrs:{"color":"primary","content":_vm.productList.productListCnis.length == 0
            ? '0'
            : _vm.productList.productListCnis.length}},[_c('span',{staticClass:"mr-1"},[_vm._v("Productos ajenos al cuadro de distribución")])]),_c('v-icon',{staticClass:"pb-2"},[_vm._v("feather icon-file-text")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"1","eager":""}},[_c('TableProductAddDistributionComponent',{attrs:{"headers":_vm.headersDistributionProducts,"dataList":_vm.productListDistributionTable}})],1),_c('v-tab-item',{attrs:{"value":"2","eager":""}},[_c('TableProductAddComponent',{attrs:{"headers":_vm.headersCnisProducts,"dataList":_vm.productList.productListCnis,"deleteProductList":_vm.deleteProductList}}),_c('validation-observer',{ref:"observer_add",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var invalid = ref.invalid;
return [_c('form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.addProductCnis.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 pt-9"},[_c('validation-provider',{attrs:{"name":"product","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"br",attrs:{"error-messages":errors,"auto-select-first":"","items":_vm.productFormList,"outlined":"","label":_vm.$t('label.product')},on:{"change":function($event){return _vm.getProduct()}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{staticClass:"pt-5"},[_vm._v(" "+_vm._s(data.item.text)+" ")])]}},{key:"selection",fn:function(data){return [_c('div',{staticClass:"pt-5"},[_vm._v(" "+_vm._s(data.item.text)+" ")])]}}],null,true),model:{value:(_vm.itemProduct),callback:function ($$v) {_vm.itemProduct=$$v},expression:"itemProduct"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"quantity","rules":"required|numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"br",attrs:{"counter":8,"outlined":"","dense":"","maxlength":8,"error-messages":errors,"label":_vm.$t('label.quantity'),"required":""},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4 pt-4"},[_c('button',{staticClass:"btn btn-outline-success",attrs:{"type":"submit","disabled":invalid}},[_c('i',{staticClass:"feather icon-plus mr-1"}),_vm._v(" "+_vm._s(_vm.$t("global.add_product"))+" ")])])])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }