<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-xl-12">
        <div class="card">
          <div class="card-body">
            <validation-observer ref="requestForm">
              <div class="row">
                <div class="col-md-12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="ref_request"
                    rules="required|max:100"
                  >
                    <v-text-field
                      v-model="modelRequest.refereceRequest"
                      :error-messages="errors"
                      :label="$t('label.ref_prerequest')"
                      required
                      oninput="this.value = this.value.toUpperCase()"
                      counter
                      maxlength="100"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="type_request"
                    rules="required"
                  >
                    <v-autocomplete
                      auto-select-first
                      :error-messages="errors"
                      :no-data-text="$t('label.no_results')"
                      :items="prerequest"
                      v-model="modelRequest.typeRequest"
                      :label="$t('label.type_prerequest')"
                      required
                    />
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <AllBranchOfficeSupplierComponent
                    ref="branchOfficeDestination"
                    v-on:branchChange="branchChange"
                    :branchLabel="$t('shipment_request.destination_branch')"
                  />
                </div>
                <div class="col-12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="comment"
                    rules="required|min:1|max:5000"
                  >
                    <v-textarea
                      outlined
                      v-model="modelRequest.comments"
                      :error-messages="errors"
                      :label="$t('label.comment')"
                      counter
                      maxlength="5000"
                      rows="4"
                      class="br"
                      oninput="this.value = this.value.toUpperCase()"
                      append-icon="feather icon-message-square"
                      required
                    />
                  </validation-provider>
                </div>
              </div>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Este es el componente de RequestFormComponent
 * @component RequestFormComponent
 * @lifecycle mounted Envia el valor que se emcuentra en modelRequest al common AllBranchOfficeSupplierComponent
 * @author Jesus Teran
 */
import prerequest from "@/assets/json/prerequest.json";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AllBranchOfficeSupplierComponent from "@/common/select/AllBranchOfficeSupplierComponent";

import { PRODUCT_PREREQUEST } from "@/core/DataTableHeadersLocale";

export default {
  props: {
    /** @vprop {Object} [modelRequest={}] */
 
    modelRequest: Object,
  },
  data() {
    return {
      /** * @member {Object} */
      listProduts: [],
      /** * @member {Array} */
      headers: PRODUCT_PREREQUEST(),
      sending: false,
      loading: false,
    };
  },
  methods: {
    /**
     * Realiza el cambio de sucursal destino en el common AllBranchOfficeSupplierComponent
     * @method
     * @param {Object} item El item es el objecto modelRequest donde se encuntra el id del de la sucursal destino
     * este se envia al common AllBranchOfficeSupplierComponent para que en caso de tener un valor se auto seleccione el del valor de modelRequest.
     */
    branchChange(item) {
      this.modelRequest.destination = item.idBranchOffice;
    },
    /**
     * Realiza la validación del formulario antes de enviar el formulario
     * @method
     * @returns {Boolean} Retorna una valor (false): el formulario esta incorrecto / (true) El formulario fue llenado de manera correcta
     */
    async validateForm() {
      return await this.$refs.requestForm.validate();
    },
    /**
     * Realiza la limpia de los valores del modelRequest,
     * en caso de no tener el atributo idShipmentRequest los valores se reinicia en caso contrario se utiliza para realizar la modificacion del mismo
     * @method
     */
    clear() {
      if (!this.modelRequest.idShipmentRequest) {
        this.modelRequest.refereceRequest = "";
        this.modelRequest.typeRequest = "";
        this.modelRequest.destination = "";
        this.modelRequest.comments = "";
      }
    },
  },
  mounted() {
    if (this.modelRequest.idShipmentRequest) {
      this.$refs.branchOfficeDestination.reloadComponentIdBranchOffice(
        this.modelRequest.supplierRequest
      );
    }
  },
  created() {
    this.clear();
    this.prerequest = prerequest;
  },
  watch: {
    /**
     *
     * @watch items
     * @desc on items change, do some stuff
     */
    "$i18n.locale": function() {
      this.headers = PRODUCT_PREREQUEST();
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    AllBranchOfficeSupplierComponent,
  },
};
</script>
