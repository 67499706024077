<template>
  <div class="p-2 pb-8">
    <v-data-table
      :headers="headers"
      :items="dataList"
      :search="search"
      height="auto"
      fixed-header
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [10, 15, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
    >
      <template v-slot:top>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <v-text-field
              v-model="search"
              :label="$t('table.search')"
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top v-if="isDelete">
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-2"
              @click="deleteProduct(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-trash-2"></i>
            </span>
          </template>
          <span>{{ $t("table.delete") }}</span>
        </v-tooltip>
        <div v-if="!isDelete">-</div>
      </template>
    </v-data-table>
    <!--   <ButtonPrintComponent /> -->
  </div>
</template>

<script>
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";
import ButtonTableComponent from "@/common/table/ButtonTableComponent";

export default {
  props: {
    headers: {},
    dataList: null,
    isDelete: false,
    /* Fuction Request - Products */
    updateProduct: Function,
    deleteProduct: Function,
    userValidate: false,
    userRequest: false,
    userRequestAuthorization: false,
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    excelTable() {
      this.$emit("excelTable");
    },
  },
  components: {
    ButtonPrintComponent,
    ButtonTableComponent,
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .theme--light.v-data-table > .v-data-table__wrapper {
    max-height: 350px !important;
  }
}
</style>
