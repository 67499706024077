<template>
  <div>
    <div v-if="styleIcon">
      <strong> </strong>
      <v-chip label small :color="colorStatus(status)" dark>
        <v-icon class="mr-2" small>
          {{ icons(status) }}
        </v-icon>
        <span> Solicitud {{ statusName }}</span>
      </v-chip>
    </div>
    <div v-else-if="styleIconChat">
      <v-chip filter x-small :color="colorStatus(status)" dark>
        <span>  {{ statusName }}</span>
      </v-chip>
    </div>
    <div v-else>
      <v-chip :color="colorStatus(status)" dark class="m-1">
        <v-icon class="mr-2" small>
          {{ icons(status) }}
        </v-icon>
        <span class="mr-2">{{ statusName }}</span>
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: 0,
    statusName: "",
    styleIcon: false,
    styleIconChat: false,
  },
  methods: {
    colorStatus(statusChip) {
      let color = "";
      switch (statusChip) {
        case 1:
          /* Pendiente de envio para validar */
          color = "#8898aa";
          break;
        case 2:
          /* Cancelar la solicitud */
          color = "#f44336";
          break;
        case 3:
          /* En proceso de validación */
          color = "#495057";
          break;
        case 4:
          /* Pendiente de autorizar */
          color = "#00b4d8";
          break;
        case 5:
          /* Autorizada */
          color = "#4caf50";
          break;
        case 6:
          /* En proceso de revalidación */
          color = "#ffb703";
          break;
        case 7:
          /* Pendiente de Reautorizar */
          color = "#f77f00";
          break;
        case 8:
          /* Rechazada en validación */
          color = "#f44336";
          break;
        case 9:
          /* Rechazada en revalidación */
          color = "#f44336";
          break;
        case 10:
          /* Rechazada en autorización */
          color = "#f44336";
          break;
        default:
          color = "#212529";
      }
      return color;
    },
    icons(statusChip) {
      let color = "";
      switch (statusChip) {
        case 1:
          color = "feather icon-clock";
          break;
        case 2:
          color = "feather icon-slash";
          break;
        case 3:
          color = "feather icon-list";
          break;
        case 4:
          color = "feather icon-clipboard";
          break;
        case 5:
          color = "feather icon-check-circle";
          break;
        case 6:
          color = "feather icon-repeat";
          break;
        case 7:
          color = "feather icon-repeat";
          break;
        case 8:
          color = "feather icon-minus-circle";
          break;
        case 9:
          color = "feather icon-minus-circle";
          break;
        case 10:
          color = "feather icon-minus-circle";
          break;
        default:
          color = "feather icon-zap";
      }
      return color;
    },
  },
};
</script>

<style scoped>
.v-chip.v-size--default {
  border-radius: 18px !important;
  font-size: 14px;
}
.v-chip.v-size--x-small {
  border-radius: 18px !important;
  font-size: 11px;
  height: 20px;
}
.v-chip.v-size--small {
  border-radius: 12px !important;
  font-size: 13px;
  height: 26px;
}
</style>
