<template>
  <validation-observer>
    <form>
      <validation-provider v-slot="{ errors }" name="supplier" rules="required">
        <v-autocomplete
          :disabled="isDisabled"
          v-model="idBranchOffice"
          :items="branchOfficeList"
          @change="branchChange"
          :label="branchLabel"
          :error-messages="errors"
        />
      </validation-provider>
    </form>
  </validation-observer>
</template>
<script>
import { BranchOfficeRequest } from "@/core/request";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  props: {
    branchLabel: "",
  },
  data() {
    return {
      idBranchOffice: null,
      branchOfficeList: [],
      isDisabled: false,
    };
  },
  methods: {
    branchChange(data) {
      this.$emit("branchChange", this.idBranchOffice);
    },
    reloadComponentDisabled(isDisabled) {
      this.isDisabled = isDisabled;
    },
    reloadComponentIdBranchOffice(id) {
      this.idBranchOffice = { idBranchOffice: id };
    },
    reloadComponentClean() {
      this.idBranchOffice = "";
    },
  },
  created() {
    BranchOfficeRequest.listAllActive().then(({ data: { data } }) => {
      this.branchOfficeList = data.data.map(
        ({ idBranchOffice, name, branchOfficeKey }) => ({
          value: { idBranchOffice },
          text: `${name} - ${branchOfficeKey}`,
        })
      );
    });
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};
</script>
