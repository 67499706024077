<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :items="dataList"
      :search="search"
      height="auto"
      fixed-header
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [-1],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
    >
      <template v-slot:top>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <v-text-field
              v-model="search"
              :label="$t('table.search')"
            ></v-text-field>
          </div>
        </div>
      </template>
      <template v-slot:[`item.quantity`]="{ item }">
        <validation-provider
          v-slot="{ errors }"
          name="quantity"
          rules="numeric|min_value:1"
        >
          <v-text-field
            v-model="item.quantity"
            :counter="8"
            :maxlength="8"
            :error-messages="errors"
            required
          />
        </validation-provider>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";

import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";
import { SwalDelete } from "@/core/SwalAlert";
export default {
  props: {
    headers: {},
    dataList: {
      required: true,
      default: {},
    },
    isDelete: false,
    deleteProductList: Function,
  },
  data() {
    return {
      search: "",
      dialog: false,
      item: {
        products: {
          productKey: "",
          name: "",
        },
      },
    };
  },
  methods: {
    async deleteRecord(item) {
      const { isConfirmed } = await SwalDelete.fire({
        title: this.$t("shipment_request.title_delete"),
        html:
          '<div class="pb-2"><b>' +
          this.$t("shipment_request.quantity") +
          "</b> " +
          " " +
          item.quantityOrdersProduct +
          "</br></div>" +
          "<div ><b>" +
          this.$t("shipment_request.description") +
          "</b>" +
          item.products.name +
          "</div>",
      });
      if (!isConfirmed) {
        return;
      }
      this.deleteProductList(item);
      this.dialog = false;
      this.item = {
        products: {
          productKey: "",
          name: "",
        },
      };
    },
    updateItem(item) {
      this.$emit("updateItem", item);
    },
    reloadTable(item) {},
  },
  created() {},
  components: {
    ButtonPrintComponent,
    ValidationProvider,
  },
};
</script>
